/* index.css */
@import url('./fonts.css');

body {
  margin: 0;
  font-family: 'Tiempos Regular', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F1EFE9; /* Claude background */
  color: #29261B; /* Claude text */
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Styrene Bold', sans-serif;
}

.custom-bg-color {
  background-color: #F8F8F6; /* Example: light gray background */
}

.custom-switch {
  display: flex;
  align-items: center;
}

.custom-switch span {
  color: #fff; /* Adjust based on your navbar's color scheme */
}

.custom-toggle {
  display: flex;
  align-items: center;
}

.label-text {
  color: #fff; /* or any color that fits the navbar theme */
  margin: 0 5px; /* Adjust spacing around the switch */
}

.form-inline .form-check {
  align-self: center;
}

.brand-logo {
  font-size: 1.5rem;
  font-family: "Playwrite NZ", cursive;
}

.nav-link {
  margin-left: 20px;
  color: #fff;
}

.nav-link svg {
  width: 24px;
  height: 24px;
  fill: #fff;
}

.nav-link:hover svg {
  fill: #61dafb;
}

.nav-link.active svg {
  fill: #61dafb;
}

.btn-custom {
  font-family: 'Styrene Bold', sans-serif;
}

.navbar-logo {
  height: 1.5em;
  vertical-align: middle;
}

.navbar-icon {
  height: 1.5em;
  width: 1.5em;
  vertical-align: middle;
}
