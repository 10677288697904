/* Show.css */
.transcript-section {
  background-color: #F8F8F6;
  padding: 15px;
}

.transcript-text {
  display: inline;
}

.transcript-speaker {
  margin-bottom: 10px;
}

.clickable-card {
  cursor: pointer;
}

.speaker-name {
  font-weight: normal;
}

.time-marker {
  font-weight: bold;
  margin-right: 10px;
}

@media (max-width: 767.98px) {
  .scrollable-right-column {
    margin-left: 0;
  }
}
